<template>
  <div>
    <b-nav-item-dropdown v-if="languages"
      id="dropdown-grouped"
      variant="link"
      class="dropdown-language"
      right
    >
      <template #button-content>
        <b-img
          :src="currentLocale.img"
          height="14px"
          width="22px"
          :alt="currentLocale.locale"
        />
        <span class="ml-50 text-body">{{ currentLocale.name }}</span>
      </template>
      <b-dropdown-item
        v-for="localeObj in langLocales"
        :key="localeObj.locale"
        @click="$i18n.locale = localeObj.locale"
      >
        <b-img
          :src="localeObj.img"
          height="14px"
          width="22px"
          :alt="localeObj.locale"
        />
        <span class="ml-50">{{ localeObj.name }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'CbsLocale',
  components: {
    CbsDebug,
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      languages: null,
      langLocales: null,
      userLocale: null,
    }
  },
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
      // return this.locales.find(l => l.locale === this.sessionLocale())
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      {
        locale: 'ru',
        img: require('@/assets/images/flags/ru.png'),
        name: 'Русский',
      },
      /*
      {
        locale: 'fr',
        img: require('@/assets/images/flags/fr.png'),
        name: 'French',
      },
      {
        locale: 'de',
        img: require('@/assets/images/flags/de.png'),
        name: 'German',
      },
      {
        locale: 'pt',
        img: require('@/assets/images/flags/pt.png'),
        name: 'Portuguese',
      },
      */
    ]
    /* eslint-disable global-require */

    return {
      locales,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loadLanguages()
      this.$i18n.locale = (useCubus.userLocale() || 'en')
    },
    loadLanguages() {
      useJwt.query({
        query: {
          method: 'languages',
          param: {},
        },
      })
        .then(response => {
          if (response.data.thread) this.threadLanguages(response.data.thread)
        })
        .catch(err => {
          useCubus.toastError(this, err)
        })
    },
    threadLanguages(threadName) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: {
            threadname: threadName,
          },
        },
      })
        .then(response => {
          // console.log('thread response', response)
          if (response.data.thread && response.data.thread.status === 'done' && response.data.thread.result) {
            this.languages = response.data.thread.result.languages
            this.langLocales = this.languages.map(l => this.lang2locale(l))
            // console.log('languages', this.languages)
            // console.log('langLocales', this.langLocales)
          } else if (response.data.thread && response.data.thread.status === 'error') {
            useCubus.toastError(this, response.data.thread.error)
          } else {
            this.delayLanguages(threadName)
          }
        })
        .catch(error => {
          console.log('thread error', error)
        })
    },
    delayLanguages(threadName) { setTimeout(() => this.threadLanguages(threadName), 500) },
    locales_lang() {
      this.languages.map(lang => this.lang2locale(lang))
    },
    lang2locale(lang) {
      return {
        locale: lang.sid,
        // eslint-disable-next-line import/no-dynamic-require
        img: require(`@/assets/images/flags/${lang.sid}.png`),
        name: lang.name,
      }
    },
    sessionLocale() {
      const loc = useCubus.userLocale()
      if (loc !== this.$i18n.locale) this.$i18n.locale = loc
      return loc
      // return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
}
</script>

<style>

</style>
