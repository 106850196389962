<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © 2022-{{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://cubus.kz"
        target="_blank"
      >cubus.kz</b-link>
      <span class="d-none d-sm-inline-block">&nbsp;
        by Max Park, All rights Reserved
      </span>
      <span class="small text-muted">
        &nbsp;(release: {{ release }})
      </span>
    </span>

    <span class="float-md-right d-none d-md-block">Hand-crafted &amp; Made with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      release: '0000.0000.0000',
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.release = useCubus.release()
    },
  },
}
</script>
