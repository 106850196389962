<template>
  <div style="margin-top: 5px;">
    <b-button-group size="sm" class="cbs-inline-spacing">
      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="isDebug ? 'primary' : 'outline-primary'"
                @click="isDebug = !isDebug"
      >
        <feather-icon icon="CodeIcon" />
        {{ context.$options.name }}
      </b-button>
    </b-button-group>
    <div v-if="isDebug" style="margin-left: 10px;margin-right: 10px;">
      Props: <json-viewer :value="context._props" :expand-depth=0 sort />
      Data: <json-viewer :value="context._data" :expand-depth=0 sort />
      Session: <json-viewer :value="session()" :expand-depth=0 sort />
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import JsonViewer from 'vue-json-viewer'
import { BButton, BButtonGroup } from 'bootstrap-vue'

export default {
  name: 'CbsDebug',
  directives: {
    Ripple,
  },
  components: {
    JsonViewer,
    BButtonGroup,
    BButton,
  },
  props: {
    name: {
      type: String,
      default: 'Debug',
    },
    context: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isDebug: false,
    }
  },
  methods: {
    session() {
      return this.$store.getters['cubus-store/SESSION']
    },
  },
}
</script>

<style scoped>

</style>
