import { ref } from '@vue/composition-api'
import { navLinkProps, isRouteMatched } from '@core/layouts/utils'

export default function useVerticalNavMenuLink(item) {
  const isActive = ref(false)

  const linkProps = navLinkProps(item)

  const updateIsActive = () => {
    isActive.value = isRouteMatched(item.route)
  }

  return {
    isActive,
    linkProps,
    updateIsActive,
  }
}
