<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- domain name -->
    <!--    <div style="margin-left: 10px; margin-right: 10px;">
      <span class="text-truncate">{{ domainName }}</span>
    </div>-->
    <div>
      <!--<span class="d-inline-block text-truncate" style="max-width: 100px;">
        {{ domainName }}
      </span>-->
      <span class="d-inline-block">
        {{ domainName }}
      </span>
    </div>

    <!-- Left Col -->
    <div v-if="isDev()" class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- Bookmarks Container -->
      <bookmarks />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--<locale />-->
      <cbs-locale />
      <dark-Toggler class="d-none d-lg-block" />
      <search-bar v-if="isDev()" />
      <!--<cart-dropdown />-->
      <notification-dropdown v-if="isDev()" />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import useCubus from '@/cubus/services/useCubus'
import CbsLocale from '@/cubus/components/app-navbar/components/CbsLocale.vue'
import Bookmarks from './components/Bookmarks.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    CbsLocale,
    BLink,
    BNavbarNav,
    Bookmarks,
    SearchBar,
    DarkToggler,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      domain: null,
    }
  },
  computed: {
    domainName() {
      const sess = useCubus.getSession()
      if (sess) {
        return sess.domain.name
      }
      return 'no_domain'
    },
  },
  created() {},
  methods: {
    isDev() {
      useCubus.isDeveloper()
    },
  },
}
</script>
