<template>
  <cbs-layout-vertical>
    <router-view />

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />

  </cbs-layout-vertical>
</template>

<script>
import CbsLayoutVertical from '@/cubus/components/layout-vertical/CbsLayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    AppCustomizer,
    CbsLayoutVertical,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
